import React from "react";

import { appURLs } from "@/app/constants.mjs";
import Patch808 from "@/game-val/articles/patch808.mdx";
import GameArticle from "@/shared/GameArticle.jsx";
import { useRoute } from "@/util/router-hooks.mjs";

const PATCH_TO_PATCH_NOTES_MAP = {
  patch800: {
    title: ["val:meta.patchNotes.patch808.title", "VALORANT Patch Notes 8.08"],
    subTitle: [
      "val:patchNotes.patch808.title",
      "Agent & Premier Updates! Bug Fixes!",
    ],
    description: [
      "val:meta.patchNotes.patch808.description",
      "Check out the VALORANT Patch Notes 8.08",
    ],
    content: Patch808,
    date: new Date("Apr 30, 2024 10:00:00"),
    imageURL: `${appURLs.CDN}/blitz/val/articles/val-patch-808.webp`,
  },
};

function PatchNotes() {
  const { parameters } = useRoute();
  const patch = parameters[0];
  const content =
    PATCH_TO_PATCH_NOTES_MAP[patch] ||
    Object.values(PATCH_TO_PATCH_NOTES_MAP).sort(
      (a, b) => b.date.getTime() - a.date.getTime(),
    )[0];

  return (
    <GameArticle
      articleHeader={{
        title: content.title,
        subTitle: content.subTitle,
        date: content.date,
        imageURL: content.imageURL,
      }}
      ContentMDX={content.content}
      components={undefined}
    />
  );
}

export default PatchNotes;

export const meta = (parameters) => {
  const patch = parameters[0];

  const content =
    PATCH_TO_PATCH_NOTES_MAP[patch] ||
    Object.values(PATCH_TO_PATCH_NOTES_MAP).sort(
      (a, b) => b.date.getTime() - a.date.getTime(),
    )[0];

  return {
    title: content.title,
    description: content.description,
    subtitle: true,
    image: {
      url: content.imageURL,
      alt: content.title,
      width: 656,
      height: 369,
    },
  };
};
